<template>
  <b-modal
    id="activityDetailsModal"
    :title="modalTitle"
    size="lg"
    hide-footer
  >
    <b-card>
      <b-table-simple
        hover
        caption-top
        responsive
        class="rounded-bottom mb-0 table table-bordered"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>Task</b-th>
            <b-th>Contact Person</b-th>
            <b-th>Visitors</b-th>
            <b-th>Purpose</b-th>
            <b-th>Discussion</b-th>
            <b-th>Outcome</b-th>
            <!-- <b-th>Document</b-th> -->
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item, index) in activityDetailsData"
            :key="index"
          >
            <b-td class="text-left">
              {{ item.subject }}
            </b-td>
            <b-td>
              {{ item.contact_name }}
            </b-td>
            <b-td>
              {{ item.username }}
            </b-td>
            <b-td>
              {{ item.purpose }}
            </b-td>
            <b-td>
              {{ item.discussion }}
            </b-td>
            <b-td>
              {{ item.outcome }}
            </b-td>
           <!-- <b-td>
              <span v-if="item.attachment">
                <b-img
                  v-if="images.includes(getFileExtension(ulist.attachment))"
                  :src="require('@/assets/images/icons/jpg.png')"
                  height="auto"
                  width="15"
                  class="mr-1"
                />
                <b-img
                  v-if="pdf.includes(getFileExtension(ulist.attachment))"
                  :src="require('@/assets/images/icons/pdf.png')"
                  height="auto"
                  width="15"
                  class="mr-1"
                />
                <b-img
                  v-if="docs.includes(getFileExtension(ulist.attachment))"
                  :src="require('@/assets/images/icons/doc.png')"
                  height="auto"
                  width="15"
                  class="mr-5"
                />
                <a
                  :href="ulist.attachment"
                  target="_blank"
                ><span class="align-bottom">attachment.{{ getFileExtension(ulist.attachment) }}</span>
                </a>
              </span>
            </b-td>-->
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </b-modal>
</template>

<script>

export default {
  name: 'ActivityDetailsModal',
  components: {
  },
  props: ['activityDetailsData'],
  data() {
    return {
      openModal: false,
      modalTitle: 'Activity Details',
      modalData: [],
      excelData: [],
    }
  },
  watch: {
    activityDetailsData: {
      handler(val) {
        this.modalData = val
        this.openModal = true
      },
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', componentId => {
      if (componentId === 'activityDetailsModal') {
        this.openModal = false
      }
    })
  },
}
</script>
